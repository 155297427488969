<template>
  <nav
    :class="{
      nav: true,
      'nav--collapsable': collapsable,
      'nav--mobile': layout.breakpoint == 1,
      'nav--hidden': collapsable ? !opened : false,
    }"
  >
    <div class="nav__content" v-if="!loading.course">
      <div class="nav__header">
        <span class="nav__header__title">{{ course.title }}</span>
        <div class="nav__header__progress">
          <span class="nav__header__progress__label"
            > {{ $t("my_courses.progress") }} {{ coursePercentage }}%</span
          >
          <IconSax name="medal-star" primary :type="courseIsCompleted ? 'bold' : 'outline'" />
        </div>
        <ProgressBar :percentage="coursePercentage ? coursePercentage : 0" />
      </div>

      <AccordionPlay
        v-for="module in modules"
        :key="module.id"
        :title="module.title"
        :type="!module.is_liberated ? 'lesson' : ''"
        :defaultExpanded="module.id == getCurrentModuleOpened && openedLesson"
        :lock="module.is_liberated === false"
        @toggle="
          module.is_liberated ? openLesson({ ...module }) : denyCourse(module)
        "
        class="module"
      >
        <ul class="nav__class__list" v-if="loadingModule && loading.module">
          <li class="spinner">
            <b-spinner label="Loading..."></b-spinner>
          </li>
        </ul>
        <ul class="nav__class__list" v-else>
          <li
            v-for="lesson in currentModuleLessons"
            :key="lesson.id"
            @click="
              lesson.is_liberated
                ? gotoLesson({
                    courseId: lesson.course_id,
                    moduleId: lesson.module_id,
                    lessonId: lesson.id,
                    type: 2,
                  })
                : denyCourse(lesson)
            "
            :class="{
              nav__class__link: true,
              'nav__class__link--completed':
                lesson.is_liberated && lesson.is_completed,
              'nav__class__link--blocked': lesson.is_liberated === false,
              'nav__class__link--selected':
                lesson.id == $route.params.lessonId ||
                lesson.id == currentLesson.id,
            }"
          >
            <IconSax :name="getLessonIcon(lesson)" type="bold" />
            <span class="nav__class__link__label">{{ lesson.title }}</span>
          </li>
        </ul>
      </AccordionPlay>
    </div>
    <div
      v-if="opened"
      @click="$emit('close')"
      :class="{
        nav__overlay: true,
        'nav__overlay--mobile': layout.breakpoint == 1,
      }"
    ></div>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Rating from "@/components/common/cards/rating/Rating.vue";
import AccordionPlay from "@/components/common/AccordionPlay.vue";

export default {
  components: {
    Rating,
    AccordionPlay,
    ProgressBar: () =>
      import("@/components/common/progressBar/ProgressBar.vue"),
  },
  props: ["gotoLesson", "opened", "collapsable"],
  computed: {
    ...mapGetters({
      layout: "layout/getLayout",
      isMobile: "layout/getIsMobile",
      isMedium: "layout/getIsMedium",
      settings: "config/getSettings",
      course: "courses/getCourse",
      modules: "courses/getModules",
      currentLesson: "courses/getCurrentLesson",
      getParams: "courses/getParams",
      currentModuleLessons: "courses/getCurrentModuleLessons",
      coursePercentage: "courses/getCoursePercentage",
      loading: "courses/getLoading",
      loadingModule: "home/getLoadingModule",
      getCurrentModuleOpened: "courses/getCurrentModuleOpened",
    }),
    courseIsCompleted() {
      return this.coursePercentage === 100 ||
                  (this.course.percentage_release_certificate !== null &&
                    this.course.percentage_complete >=
                    this.course.percentage_release_certificate);
    },
  },
  watch: {
    currentLesson(item) {
      this.denyCourse(item);
    },
  },
  data() {
    return {
      openedLesson: true,
    };
  },
  beforeDestroy () {
    this.actionCurrentLessonOpened(null);
  },
  methods: {
    ...mapActions({
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
      actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
      actionShowModalBuyCourse: "home/actionShowModalBuyCourse",
      actionCurrentLessonOpened: "courses/actionCurrentLessonOpened",
    }),

    async openLesson(module) {
      let statusOpened = this.openedLesson;

      this.openedLesson = !this.openedLesson;

      if (statusOpened === true && this.getCurrentModuleOpened !== module.id) {
        this.openedLesson = true;
      }

      this.actionCurrentLessonOpened(module.id);

      const values = {
        courseId: module.course_id,
        moduleId: module.id,
        type: 1,
      };

      await this.gotoLesson(values);
    },
    async denyCourse(item) {
      if (item.is_liberated === false) {
        const data = {
          rule: item.rule_liberation,
          date: item.liberated_at,
          type: item.module_id ? 1 : 4,
        };

        this.actionShowModalInfoCourse({
          active: true,
          data,
        });
      }
    },
    getLessonIcon(lesson) {
      if (!lesson.is_liberated) {
        return "lock";
      }
      switch (lesson.mediaType) {
        case "text":
          return "clipboard-text";
        case "audio":
          return "microphone";
        case "quiz":
          return "clipboard-tick";
        case 'exercise': 
          return 'clipboard-tick';
        default:
          return "video-circle";
      }
    },
  },
};
</script>
<style scoped lang="scss">

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > .spinner-border {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.nav {
  ::v-deep .accordionPlay__header--title {
    font: var(--body-bold-14) !important;
  }

  flex-shrink: 0;
  height: 100%;
  overflow: auto;
  width: 22rem;

  &--collapsable {
    position: absolute;
    z-index: 999;
    right: 0;
  }

  &--mobile {
    position: absolute;
    z-index: 999;
    width: 100vw;
    bottom: 0;
    border-radius: 2rem 2rem 0 0;
  }

  &--hidden {
    display: none;
  }
}

.nav__overlay {
  position: fixed;
  z-index: 9;
  height: 100%;
  right: 22rem;
  width: 100vw;

  &--mobile {
    top: 0;
    height: 100vh;
    background: #000;
    opacity: 0.3;
    right: 0;
  }
}

::v-deep .module {
  width: 100%;

  .accordionPlay__header--title {
    @extend .body-bold-18;
  }

  .accordionPlay__content {
    padding: 1rem;
  }
}

.nav__class__list {
  all: unset;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

::v-deep .nav__class__link {
  all: unset;
  position: relative;
  color: var(--fontcolor);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer !important;
  width: 100%;

  svg path {
    fill: var(--fontcolor) !important;
  }

  :hover {
    color: var(--maincolor);
  }

  &--completed svg path {
    fill: var(--successcolor) !important;
  }

  &--completed i {
    color: var(--successcolor) !important;
  }

  &--selected {
    font-weight: 900;
  }

  &--blocked {
    opacity: 0.5;
  }
}

.nav__class__link__label {
  flex: 1;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.nav__header {
  display: flex;
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.nav__header__title {
  align-self: stretch;
  overflow: hidden;
  color: var(--fontcolor);
  text-overflow: ellipsis;

  /* Body/Semibold/16px */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.2rem */
}

::v-deep .nav__header__progress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  svg {
    height: 1rem;
    width: 1rem;
  }

  svg path {
    fill: var(--maincolor);
  }
}

.nav__header__progress__label {
  flex: 1 0 0;
  color: var(--fontcolor);

  /* Body/Semibold/12px */
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 0.9rem */
}

.nav__content {
  display: flex;
  z-index: 999;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  flex-shrink: 0;
  width: 100%;
  overflow: auto;

  border-left: 1px solid var(--bordercolor2);
  background: var(--backgroundcolor, #141416);
}

.progress {
  height: 0.25rem;
  align-self: stretch;
}
</style>
